export const REGIONS = [
  "ETELÄINEN",
  "LÄNTINEN",
  "KESKINEN",
  "POHJOINEN",
  "KOILLINEN",
  "KAAKKOINEN",
  "ITÄINEN & ÖSTERSUNDOM",
];

export const DATES = ["20220820", "20220821"];
export const HOURS = [12, 13, 14, 15, 16, 17, 18, 19];
export const MINUTES = [0, 30];

export const MAIN_APP_HOST = "https://taidelahja.helsinkifestival.fi";

export const MAPBOX_COUNTRY_CODE = "FI";
export const MAPBOX_REGION_PLACE_NAME = "Helsinki";
